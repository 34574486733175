import cookie from 'js-cookie';

import { useState, useEffect } from 'react';

const CookieAccept = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (cookie.get('COOKIE_ACCEPT') || cookie.get('COOKIE_DECLINE')) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    cookie.set('COOKIE_ACCEPT', 'true', { expires: 365 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    cookie.set('COOKIE_DECLINE', 'true');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="flex fixed justify-between md:px-24 px-16 py-8 transform w-84p md:max-w-1036 text-14 z-1000 items-center bg-white shadow-lg bottom-3 left-1/2 -translate-x-1/2 md:flex-row flex-col rounded-md md:rounded-none gap-8">
      <p>
        By using weprodigi.com you agree to our use of cookies for personalised content, according to Our{' '}
        <a className="text-purple underline" href="https://weprodigi.com/privacy-policy">
          Data Privacy
        </a>{' '}
      </p>
      <button
        className="whitespace-nowrap flex items-center justify-center get-offer-button bg-green-badge hover:bg-green-hover transition-all transition-400 text-white px-22  py-5 text-14 rounded-md cursor-pointer md:w-85 w-full "
        onClick={handleAccept}
      >
        ACCEPT
      </button>
      <button
        className="whitespace-nowrap flex items-center justify-center get-offer-button bg-gray-400 hover:bg-gray-500 transition-all transition-400 text-white px-22  py-5 text-14 rounded-md cursor-pointer md:w-85 w-full "
        onClick={handleDecline}
      >
        DECLINE
      </button>
    </div>
  );
};
export default CookieAccept;
