import '../styles/globals.css';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import App from 'next/app';
import '../styles/TeamAnimation.scss';
import '../styles/TeamAnimation.scss';
import CookieAccept from '../components/cookieAccept';
const Layout = dynamic(() => import('../components/Layout'));
const Header = dynamic(() => import('../components/Header'));
const Footer = dynamic(() => import('../components/Footer'));

import fetch from 'isomorphic-unfetch';
import useSWR from 'swr';

import Script from 'next/script';

const HOSTNAME = 'https://weprodigi.com/';
// const API_URL = 'https://geoip.weprodigi.com/';
const API_URL = 'https://geoip.weprodigi.com/country.php';
async function fetcher(url) {
  const res = await fetch(url);
  return await res.json();
}
const MyApp = ({ Component, pageProps, router }) => {
  const formattedPath = router.asPath.replace('/', '');
  const canonicalUrl = (HOSTNAME + formattedPath).split('?')[0];
  const [width, setWidth] = useState(0);
  const [heigth, setHeigth] = useState(0);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    async function registerSW() {
      if ('serviceWorker' in navigator) {
        try {
          await navigator.serviceWorker.register('/sw.js');
        } catch (e) {
          console.log(`SW registration failed`);
        }
      }
    }

    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
      setHeigth(window.innerHeight);
    });

    window.addEventListener(
      'load',
      async () => {
        await registerSW();
      },
      false
    );
    return () => {
      window.removeEventListener('resize', {}, false);
      window.removeEventListener('load', {}, false);
    };
  }, []);
  let location = useSWR(API_URL, fetcher);
  let currency = 'usd';
  let country = 'undefined';
  let continent = 'undefined';
  const hide = () => {
    setShowPopup(false);
  };

  if (location.data) {
    continent = location.data.continent;
    country = location.data.country;
    console.log(country);
    if (location.data.continent === 'Europe' && location.data.country !== 'GB') {
      currency = 'eur';
    } else {
      switch (location.data.country) {
        case 'US':
          currency = 'usd';
          break;
        case 'GB':
          currency = 'gbp';
          break;
        case 'AU':
          currency = 'aud';
          break;
        case 'NZ':
          currency = 'nzd';
          break;
        case 'SG':
          currency = 'sgd';
          break;
        case 'CA':
          currency = 'cad';
          break;
        case 'RU':
          currency = 'rub';
          break;
        case 'SA':
          currency = 'sa';
          break;
        case 'AE':
          currency = 'ae';
          break;
        case 'QA':
          currency = 'qa';
          break;
        case 'KW':
          currency = 'kw';
          break;
        default:
          currency = 'usd';
          break;
      }
    }
  }
  if (['/lucky-prodigist', '/new-year-landing/[costumer]'].includes(router.pathname)) {
    return (
      <>
        <Head>
          <link rel="canonical" href={canonicalUrl} />
        </Head>

        <Layout country={country} showPopup={showPopup} hide={hide}>
          {!['/services/digital-marketing-services-in-saudi', '/services/marketing-services-form'].includes(router.pathname) && <CookieAccept />}

          <a className="skip-link" href="#maincontent">
            Skip to main
          </a>
          <Script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PMXZF5D');`}
          </Script>

          <main id="maincontent">
            <Component {...pageProps} country={country} currency={currency} width={width} heigth={heigth} />
          </main>
        </Layout>
      </>
    );
  } else {
    return (
      <>
        <Head>
          <link rel="canonical" href={canonicalUrl} />
        </Head>

        <Layout country={country} showPopup={showPopup} hide={hide}>
          {!['/services/digital-marketing-services-in-saudi', '/services/marketing-services-form'].includes(router.pathname) && <CookieAccept />}
          <a className="skip-link" href="#maincontent">
            Skip to main
          </a>
          <Script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PMXZF5D');`}
          </Script>

          <Header country={country} continent={continent} />
          <main id="maincontent">
            <Component {...pageProps} country={country} currency={currency} width={width} heigth={heigth} />
          </main>
          <Footer country={country} />
        </Layout>
      </>
    );
  }
};
MyApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};
export default MyApp;
